<template>
  <div class="px-5 pt-10 pb-5">
    <!--Main-->
    <v-row no-gutters>
      <v-btn
        @click="openSideBar('adding')"
        height="30"
        width="155"
        outlined
        class="mb-4 btn-primary rounded-lg text-none fz-13 fw-60"
      >
        + Add New Domain
      </v-btn>
    </v-row>
    <!--Headers-->
    <v-row no-gutters class="mb-4">
      <v-col cols="3" class="fz-14 font-weight-bold">Domain name</v-col>
      <v-col cols="2" class="fz-14 font-weight-bold">Registrar</v-col>
      <v-col cols="2" class="fz-14 font-weight-bold">Login</v-col>
      <v-col cols="2" class="fz-14 font-weight-bold">Password</v-col>
      <v-col cols="2" class="fz-14 font-weight-bold">Expire</v-col>
    </v-row>
    <!--Rows-->
    <v-row no-gutters>
      <v-row
        no-gutters
        v-for="item in domainsList"
        :key="item.id"
        class="width-100 pointer pointer-row"
        @click="openSideBar('read', item)"
      >
        <v-col cols="3" class="pa-4 fz-14 border-lightgray border-b-none border-l-none">{{ item.domainName }}</v-col>
        <v-col cols="2" class="pa-4 fz-14 border-lightgray border-b-none">
          <a :href="item.registrar" target="_blank" @click.stop>
            {{ item.registrar }}
          </a>
        </v-col>
        <v-col cols="2" class="pa-4 fz-14 border-lightgray border-b-none">{{ item.login }}</v-col>
        <v-col cols="2" class="pa-4 fz-14 border-lightgray border-b-none">{{ item.password }}</v-col>
        <v-col
          cols="2"
          class="pa-4 fz-14 border-lightgray border-b-none"
          :class="getEndingDomainDate(item.expireDate) ? 'text-pink' : ''"
        >
          {{ formatDate(item.expireDate) }}
        </v-col>
        <v-col class="border-lightgray border-b-none border-r-none d-flex justify-center align-center">
          <v-icon @click.stop="openDeleteDialogFromTable(item)" size="20"  class="stroke-gray">$delete</v-icon>
        </v-col>
      </v-row>
    </v-row>
    <!--Others-->
    <!--SideBar-->
    <v-navigation-drawer
      fixed
      right
      width="380"
      class="domain-info"
      v-model="sideBar.isOpen"
    >
      <v-col class="d-flex flex-column height-100 pa-0">
        <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
          <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
            {{ sideBar.mode !== 'adding' ? domain.domainName : 'Add New Domain' }}
          </span>
          <v-icon class="close-icon" @click="closeSideBar">$close</v-icon>
        </v-row>
        <v-row class="ma-0 flex-grow-1 scroll">
          <v-form ref="form" class="flex-grow-1">
            <v-col style="padding: 30px !important;">
              <!--Domain Name-->
              <p class="fz-14 mb-1 text-gray">Domain Name</p>
              <v-text-field
                :readonly="sideBar.readOnly"
                outlined
                single-line
                v-model="domain.domainName"
                class="rounded-lg input-border text-black fz-14 font-weight-medium"
                dense
                :rules="[rules.required]"
              />
              <!--Registrar-->
              <p class="fz-14 mb-1 text-gray">Registrar</p>
              <v-text-field
                :readonly="sideBar.readOnly"
                outlined
                single-line
                v-model="domain.registrar"
                class="rounded-lg input-border text-black fz-14 font-weight-medium"
                dense
              />
              <!--Login-->
              <p class="fz-14 mb-1 text-gray">Login</p>
              <v-text-field
                :readonly="sideBar.readOnly"
                outlined
                single-line
                v-model="domain.login"
                class="rounded-lg input-border text-black fz-14 font-weight-medium"
                dense
                :rules="[rules.required]"
              />
              <!--Password-->
              <p class="fz-14 mb-1 text-gray">Password</p>
              <v-text-field
                :readonly="sideBar.readOnly"
                outlined
                single-line
                v-model="domain.password"
                class="rounded-lg input-border text-black fz-14 font-weight-medium"
                dense
                :rules="[rules.required]"
              />
              <!--Expire-->
              <p class="fz-14 mb-1 text-gray">Expire</p>
              <vc-date-picker
                v-model="domain.expireDate"
                :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                :locale="{ masks: { input: 'DD.MM.YYYY' } }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    :disabled="sideBar.readOnly"
                    class="px-3 py-2 rounded-lg fz-14 font-weight-medium width-100"
                    v-mask="['##.##.####']"
                    style="border: 2px solid #E3E6EF"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </vc-date-picker>
            </v-col>
          </v-form>
        </v-row>
        <v-row class="ma-0 flex-grow-0" style="padding: 30px 0 !important;">
          <v-btn
            color="primary"
            elevation="0"
            height="40"
            width="155"
            class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none"
            outlined
            @click="openDeleteDialogFromSideBar"
            v-if="sideBar.mode === 'read'"
          >
            <v-icon left class="stroke-primary">
              $delete
            </v-icon>
            Delete
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            height="40"
            width="155"
            class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none"
            outlined
            @click="closeSideBar"
            v-if="sideBar.mode === 'adding' || sideBar.mode === 'edit'"
          >
            Cancel
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 fw-600 text-none"
            @click="sideBar.mode === 'adding' ? createDomain() : sideBar.mode === 'edit' ? updateDomain() :  openSideBar('edit', domain.id)"
          >
            {{ sideBar.mode === 'read' ? 'Edit' : 'Save' }}
          </v-btn>
        </v-row>
      </v-col>
    </v-navigation-drawer>
    <!--DeleteDialog-->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="370"
      content-class="rounded-xl"
      overlay-color="rgba(9, 24, 73, 0.2)"
      overlay-opacity="1"
      v-model="dialogDeleteIsOpen"
    >
      <v-card class="pa-7">
          <v-card-text class="py-0">
            <div class="fz-18 text-center text-black">Do you really want to delete <b>{{ domain.domainName }}</b>?</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-space-between pa-0">
            <v-btn
              class="rounded-lg text-none fz-16 fw-600"
              outlined
              width="150"
              height="40"
              @click="dialogDeleteIsOpen = false"
            >
              Return
            </v-btn>
            <v-btn
              elevation="0"
              color="#FF0000"
              width="150"
              height="40"
              class="rounded-lg white--text text-none fz-16 fw-600"
              @click="deleteDomain"
            >
              <v-icon left class="stroke-white">
                $delete
              </v-icon>
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!--Snackbars-->
    <v-snackbars
      :messages.sync="additionalComponents.messages"
      color="red"
      timeout="2000"
      multi-line
      max-width="350"
      left
      rounded="lg"
      light
      elevation="4"
      content-class="snackbar"
    >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy
          </v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <!--Overlay-->
    <v-overlay
      :value="additionalComponents.overlay"
      :z-index="0"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
    />
    <!--Loading-->
    <div
      class="preloader"
      v-if="additionalComponents.loading"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import VSnackbars from 'v-snackbars'
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'Domain',
  components: {
    'v-snackbars': VSnackbars
  },
  data() {
    return {
      additionalComponents: {
        overlay: false,
        loading: false,
        messages: []
      },
      sideBar: {
        mode: 'adding',
        isOpen: false,
        readOnly: true
      },
      dialogDeleteIsOpen: false,
      date: new Date(),
      domain: {
        id: '',
        domainName: '',
        registrar: '',
        login: '',
        password: '',
        expireDate: new Date()
      },
      domainsList: [],
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.'
      }
    }
  },
  computed: {
  },
  methods: {
    getEndingDomainDate(value) {
      return Date.parse(value) - new Date() - 604800000 < 0
    },
    openSideBar(mode, item) {
      if (mode === 'edit') {
        this.sideBar.mode = 'edit'
        this.sideBar.readOnly = false
      } else if (mode === 'adding') {
        this.sideBar.mode = 'adding'
        this.$refs.form.reset()
        this.sideBar.readOnly = false
      } else {
        this.sideBar.mode = 'read'
        this.sideBar.readOnly = true
        this.domain = JSON.parse(JSON.stringify(item))
        this.domain.expireDate = locale.parse(this.domain.expireDate)
      }
      this.sideBar.isOpen = true
    },
    closeSideBar() {
      this.sideBar.isOpen = false
      this.$refs.form.reset()
    },
    openDeleteDialogFromTable(item) {
      this.dialogDeleteIsOpen = true
      this.domain = JSON.parse(JSON.stringify(item))
    },
    openDeleteDialogFromSideBar(item) {
      this.dialogDeleteIsOpen = true
    },
    addSnack (item, result, text) {
      this.additionalComponents.messages.push({ title: result, text: text })
    },
    /* Date */
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    /* CRUD */
    async createDomain() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation createDomain($input: DomainCreateInput!) {
          cloudTools {
            createDomain(input: $input) {
              id
            }
          }
        }`,
        variables: {
          input: {
            domainName: this.domain.domainName,
            registrar: this.domain.registrar,
            login: this.domain.login,
            password: this.domain.password,
            expireDate: locale.format(this.domain.expireDate, 'YYYY-MM-DD')
          }
        }
      }).then((data) => {
        this.getDomains()
        this.addSnack('', 'Success', 'Domain: ' + this.domain.domainName + ' was created with success')
        this.closeSideBar()
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
      })
    },
    async updateDomain() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation updateDomain($input: DomainUpdateInput!) {
          cloudTools {
            updateDomain(input: $input) {
              id
            }
          }
        }`,
        variables: {
          input: {
            id: this.domain.id,
            domainName: this.domain.domainName,
            registrar: this.domain.registrar,
            login: this.domain.login,
            password: this.domain.password,
            expireDate: locale.format(this.domain.expireDate, 'YYYY-MM-DD')
          }
        }
      }).then((data) => {
        this.getDomains()
        this.addSnack('', 'Success', 'Domain: ' + this.domain.domainName + ' was updated with success')
        this.closeSideBar()
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
      })
    },
    async deleteDomain() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation deleteDomain($id: ID!) {
          cloudTools {
            deleteDomain(id: $id)
          }
        }`,
        variables: {
          id: this.domain.id
        }
      }).then((data) => {
        this.getDomains()
        this.addSnack('', 'Success', 'Domain: ' + this.domain.domainName + ' was deleted with success')
        this.closeSideBar()
        this.dialogDeleteIsOpen = false
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
      })
    },
    async getDomains() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getDomains {
          cloudTools {
            domain {
              domainName
              expireDate
              id
              login
              password
              registrar
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.domainsList = data.data.cloudTools.domain
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
      })
    }
    /* --- */
  },
  created() {
    this.getDomains()
  }
}
</script>

<style lang="scss" scoped>
.pointer-row {
  &:hover {
    background-color: #ececec38;
  }
}
</style>
